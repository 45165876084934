import React from 'react';

const estoniaPrivacyPolicyCopy = () => (
  <div>
    <p>
      Предоставляя ваши персональные данные, вы даете нам ваше согласие на:
      использование ваших данных для контактирования с вами относительно вашей
      заявки и информирования вас о другой деятельности ЕАБГ; а также для
      использования этих данных для управления вашим участием в рядах ЕАБГ. Эти
      данные будут храниться ЕАБГ в США (и/или Эстонии) (они могут быть переданы
      для обработки нашим партнерам по служению в вашей стране) только для
      вышеупомянутых целей. Для управления вашими предпочтениями и для того,
      чтобы выяснить как мы обращаемся с вашими персональными данными, см.
      <a href="https://billygraham.org/privacy-policy/" target="_blank" rel="noreferrer">"Политика конфиденциальности ЕАБГ"</a>.
    </p>
  </div>
);

export default estoniaPrivacyPolicyCopy;

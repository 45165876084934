import { GetInvolved as GetInvolvedForm } from '@bgea-js/form-templates';
import "../../../../src/content/estonia-host-page/estonia-styles.scss";
import estoniaPrivacyPolicyCopyEE from "../../../../src/content/estonia-host-page/estoniaPrivacyPolicyCopyEE";
import estoniaConfirmationEE from "../../../../src/content/estonia-host-page/estoniaConfirmationEE";
import estoniaIAAEE from "../../../../src/content/estonia-host-page/estoniaIAACopyEE";
import * as React from 'react';
export default {
  GetInvolvedForm,
  estoniaPrivacyPolicyCopyEE,
  estoniaConfirmationEE,
  estoniaIAAEE,
  React
};
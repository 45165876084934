import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
    `}<p parentName="div">{`Dear Friend,`}</p>{`
    `}<div parentName="div" {...{
        "className": "image-float-right franklin-bio"
      }}>{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "400px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "124.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFBAP/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAGoLwVbhSs4eyfXHJGA/8QAHhAAAQMEAwAAAAAAAAAAAAAAAgABAwQQEjMREyH/2gAIAQEAAQUCMsUzvaX0Y1kK7TkLh4wUOyo1L//EABYRAQEBAAAAAAAAAAAAAAAAAAERIP/aAAgBAwEBPwECY//EABYRAQEBAAAAAAAAAAAAAAAAAAEgIf/aAAgBAgEBPwFdj//EABwQAAEEAwEAAAAAAAAAAAAAAAEAAhAxIVFhcf/aAAgBAQAGPwIdnoQJVhDN6TsWIZ7P/8QAHhAAAQQBBQAAAAAAAAAAAAAAAQARITEQQVGRobH/2gAIAQEAAT8hiEgPL4Gp7FQACN3KBg45kRx4eHEETg6C6x1C9Bj/2gAMAwEAAgADAAAAEIP58f/EABcRAQEBAQAAAAAAAAAAAAAAAAEAEBH/2gAIAQMBAT8Qc1uQuf/EABgRAQADAQAAAAAAAAAAAAAAAAEAEBEx/9oACAECAQE/EE4mwDK//8QAHRABAQADAAIDAAAAAAAAAAAAAREAITFBgRBRkf/aAAgBAQABPxCIKKllmG2Spsjky5K2Aoz1zmMA7otIG7+YCTHyHF4IHQLzXcYPEL5VnvWI1789L7z/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Franklin Graham.",
            "title": "Franklin Graham.",
            "src": "/static/1609c9427165c1992cc1c110195d8e5e/066f9/FG_240111_SC2_6764_INET-1.jpg",
            "srcSet": ["/static/1609c9427165c1992cc1c110195d8e5e/f93b5/FG_240111_SC2_6764_INET-1.jpg 300w", "/static/1609c9427165c1992cc1c110195d8e5e/066f9/FG_240111_SC2_6764_INET-1.jpg 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}</div>{`
    `}<p parentName="div">{`Thanks to your generous support, our Billy Graham Rapid Response Team (BG-RRT) chaplains stand ready to deploy at a moment’s notice to communities hit by natural and man-made disasters. These crisis-trained men and women offer a listening ear and point hurting individuals to `}<em parentName="p">{`“the God and Father of our Lord Jesus Christ, the Father of mercies and God of all comfort”`}</em>{` (2 Corinthians 1:3, ESV).`}</p>{`
    `}<p parentName="div">{`Over the past few months, our chaplains have ministered in communities all across the nation—from Tennessee and New Mexico following the killing of law enforcement officers, to Ohio and Indiana in the wake of deadly tornadoes, and to Texas after wildfires burned more than 1 million acres. During every deployment, they share the Good News of Jesus Christ with hurting people—and hundreds have already responded this year by placing their faith and trust in Him.`}</p>{`
    `}<p parentName="div">{`We praise God for all those who made decisions for Christ and give Him all the glory. Will you pray with us for each of these new believers? Please also ask the Lord to continue drawing people from all walks of life to Himself through this ministry. Thank you for your commitment to sharing His love with those in the midst of crisis.`}</p>{`
    `}<p parentName="div">{`May God richly bless you,`}</p>{`
    `}<img parentName="div" {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
        "alt": ""
      }}></img>{`
    `}<p parentName="div">{`Franklin Graham | President & CEO`}</p>{`
    `}<div parentName="div" {...{
        "className": "center piece"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/04/WinchIND_240308_LB1_5677_INET-1.jpg",
          "alt": ""
        }}></img>{`
    `}<figcaption parentName="div">{`Winchester, Indiana`}</figcaption>{`
    `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
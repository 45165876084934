import React from 'react';

const estoniaIAA = () => (
    <div>
    <b>Я - АНДРЕЙ</b>
    <p>
      &quot;Я - Андрей&quot; это решение посвятить себя общению с
      Иисусом и сделать проповедь Благой вести своим главным жизненным
      приоритетом. Таким образом мы обещаем Богу:
    </p>
    <p>
      <b>МОЛИТЬСЯ</b> - Запишите имя хотя бы одного знакомого человека,
      который нуждается в Ииусе и молитесь за него каждый день.
    </p>
    <p>
      <b>ДЕЛИТЬСЯ</b> - Проводите время с этим человеком и используте
      любую возможность делиться с ним вестью об Иисусе.
    </p>
    <p>
      <b>ПРИВОДИТЬ</b> - Приведите своего друга или подругу на
      евангелизационное мероприятие. Если они откликнуться на зов
      Христа, или проявят интерес к Евангелию, они будут нуждаться в
      вашей последующей поддержке.
    </p>
    <p>
      <b>НАПРАВИТЬ</b> - Пригласите его или ее пойти вместе в церковь.
      Бог может использовать вас и вашу церковь, чтобы привести этого
      человека к углубленным отношениям с Иисусом.
    </p>
  </div>
);

export default estoniaIAA;

import React from 'react';

const estoniaIAAEE = () => (
  <div>
    <b>Ma olen ANDREAS</b>
    <p>
      Ka programmis &quot;Ma olen Andreas&quot; osalemine hõlmab pühendumist
      ajale koos Jeesusega ja hea sõnumi jagamise oma elus esikohale seadmisele.
      Sellisena on see Issanda ees võetud kohustus:
    </p>
    <p>
      <b>PALVETADA</b> - pange kirja vähemalt ühe tuttava nimi, kes vajab
      Jeesust ja palvetage tema eest iga päev
    </p>
    <p>
      <b>JAGADA</b> - veetke aega selle inimesega ja kasutage võimalust
      tutvustada talle Jeesust
    </p>
    <p>
      <b>KAASA VÕTTA</b> - tooge oma sõber evangeelsele üritusele. Kui nad
      vastavad Kristusele või ilmutavad huvi Evangeeliumi vastu, vajavad nad
      teilt jätkuvat julgustust
    </p>
    <p>
      <b>KONTAKTIHOIDMINE</b> - kutsuge neid endaga koos kirikusse. Jumal võib
      kasutada teid ja teie kogudust, mille tulemusena võib nende suhe Jumalaga
      üha sügavamaks muutuda.
    </p>
  </div>
);

export default estoniaIAAEE;

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "className": "content"
    }}>
      <p parentName="div">{`*Names and photos changed to protect privacy`}</p>
      <div parentName="div" {...{
        "id": "Tragedy-Pointed-Her-to-Eternal-Life",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`Tragedy Pointed Her to Eternal Life`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}><span parentName="h3" {...{
            "className": "city"
          }}>{`TENNESSEE`}</span></h3>{`
    Chaplains deployed to Maryville, Tennessee, after Deputy Sheriff Greg McCowan was shot and killed at a traffic 
    stop. At a memorial for the officer, chaplains met Danielle,* a young mom who was visiting the site with her toddler. She told our chaplains that she had worked with the deputy and remembered how kind he was. It was clear how upset she was over his death, but she told them she believed he was in Heaven. When they asked Danielle if she had the same assurance about where she would spend eternity, she hesitated. She shared that she tried to be a good person, so she thought she would be all right. Our chaplains compassionately shared the Gospel, and she followed along closely. Then, with tears in her eyes, she prayed to embrace `}<em parentName="div">{`“repentance that leads to salvation without regret”`}</em>{` (2 Corinthians 7:10, ESV). Before their conversation ended, they made sure Danielle had the resources she needs to grow closer to the Lord.
`}</div>
      <div parentName="div" {...{
        "id": "She-Wanted-to-Be-Good-Enough",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`She Wanted to Be Good Enough`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}><span parentName="h3" {...{
            "className": "city"
          }}>{`INDIANA`}</span></h3>{`
    `}<div parentName="div" {...{
          "className": "image-float-left"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/04/WinchesterRRT_240308_LB1_5595_INET-1.jpg",
            "alt": ""
          }}></img>{`
        `}<figcaption parentName="div">{`CRISIS-TRAINED CHAPLAINS PRAY with residents in Indiana following deadly tornadoes.`}</figcaption>{`
    `}</div>{` 
    BG-RRT chaplains met Meg* during a deployment to Winchester, Indiana, in the wake of deadly storms. Although she was overwhelmed by the damage caused by the tornado that hit her home, she was encouraged to see our chaplains and grateful for an opportunity to speak with them. She told them she believed that there must be a God because she had seen Him work in other people’s lives. When they asked Meg about her own faith, she said she worked hard but wasn’t sure she was good enough for God. When our chaplains asked if they could share the Gospel with her, she eagerly replied, “Please do.” Meg followed along as they explained `}<em parentName="div">{`“all have sinned and fall short of the glory of God”`}</em>{` but that she can find salvation `}<em parentName="div">{`“through the redemption that is in Christ Jesus”`}</em>{` (Romans 3:23–24, ESV). Meg responded by praying to place her faith and trust in Him! We thank God for calling her to Himself. Please join us in praying for her. 
`}</div>
      <div parentName="div" {...{
        "id": "Husband-and-Wife-Discover-the-Gospel-Together",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`Husband and Wife Discover the Gospel Together`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}><span parentName="h3" {...{
            "className": "city"
          }}>{`OHIO`}</span></h3>{`
    Chaplains met Judd* and his wife, Ruth,* after tornadoes tore through their neighborhood in Logan County, Ohio. The couple had been married for 58 years, and Judd served as Ruth’s caregiver after she became disabled. Their strong commitment to one another was evident, and they told the chaplains they wanted to be together until the end. When the conversations turned to faith, the pair shared that they had been married in church when they were 18—but they had never attended since. Judd and Ruth listened carefully as our chaplains shared the Good News that `}<em parentName="div">{`“if you confess with your mouth that Jesus is Lord and believe in your heart that God raised him from the dead, you will be saved”`}</em>{` (Romans 10:9, ESV). When the chaplains finished, both husband and wife said they wanted to surrender their lives to Him in repentance and faith. Will you pray for both of them?
`}</div>
      <div parentName="div" {...{
        "id": "Her-Brothers-Keeper",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`Her Brother’s Keeper`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}><span parentName="h3" {...{
            "className": "city"
          }}>{`OHIO`}</span></h3>{`
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/04/LoganCty_disabled_20240321BGEA-3360_FLAT-1.jpg",
            "alt": "Two chaplains pray with man."
          }}></img>{`
    `}</div>{`
    Brooke’s* life had been difficult before a tornado hit her mobile home in Logan County, Ohio. She takes care of her brother, who is sick and disabled. She met our chaplains when they saw her struggling to refill her generator with gasoline. During their conversation, they asked her about faith. Brooke said she hoped that all she had done for her brother—and for others—would earn her a place in Heaven someday. When our chaplains gently brought up that salvation is only possible through repentance and faith in Jesus Christ, the Holy Spirit worked in Brooke’s heart. She listened closely as they explained the Gospel in detail. We praise God that Brooke prayed to receive Jesus Christ! Please join us in praying that Brooke will learn to rest in the promise that her eternal security is `}<em parentName="div">{`“the gift of God, not a result of works, so that no one may boast”`}</em>{` (Ephesians 2:8–9, ESV).
`}</div>
      <div parentName="div" {...{
        "className": "center piece"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/04/LoganCty_destruction_240309_JD1_2440_INET-1.jpg",
          "alt": "Billy Graham Rapid Response Team, Sharing Hope in Crisis"
        }}></img>{`
    `}<figcaption parentName="div">{`THREE PEOPLE LOST THEIR LIVES after tornadoes ripped through Logan County, Ohio.`}</figcaption>
      </div>
      <div parentName="div" {...{
        "id": "He-Wanted-to-Know-About-God",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`He Wanted to Know About God`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}><span parentName="h3" {...{
            "className": "city"
          }}>{`TEXAS`}</span></h3>{`
    `}<div parentName="div" {...{
          "className": "image-float-left"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/04/Texas_pray_RRT-truck_20240305JWS-0226_FLAT-1.jpg",
            "alt": ""
          }}></img>{`
        `}<figcaption parentName="div">{`DURING EVERY DEPLOYMENT, BG-RRT chaplains share the eternal hope found only in Jesus Christ.`}</figcaption>{`
    `}</div>{`
    After wildfires devastated over 1 million acres in the Texas Panhandle, BG-RRT chaplains deployed to minister to residents, including the many who evacuated their homes. While in the town of Fritch, our team met Landon,* who was volunteering at a disaster distribution site. One of the chaplains connected with him over their shared love of cycling, and the two began to talk about his family. The young man mentioned that his family had intended to find a church for a while but had gotten too busy. Landon went on to explain that he wanted to learn more about God. The chaplains used our “Steps to Peace With God” booklet to share the Gospel with him, and he listened closely. Landon was thrilled to learn that `}<em parentName="div">{`“the Lord is good; his steadfast love endures forever, and his faithfulness to all generations”`}</em>{` (Psalm 100:5, ESV). He immediately recognized his own sinfulness and his need for the Savior. After praying to place his faith and trust in Jesus Christ, Landon asked our chaplains about next steps. They talked to him about prayer, Scripture reading, and God’s promise of the Holy Spirit. Before their time together ended, our chaplains gave him a new Bible and connected him with staff at a local church.
`}</div>
      <div parentName="div" {...{
        "id": "learn-how",
        "className": "piece"
      }}>{`
    `}<h4 parentName="div">{`Learn how to bring the love and hope of Jesus Christ to people enduring tragedy.`}</h4>{`
    `}<div parentName="div">{`
        `}<h5 parentName="div">{`PRAY`}</h5>{`
        `}<p parentName="div">{`Please pray for people across our nation and around the world who are suffering in the wake of natural and man-made disasters. Will you ask God to give BG-RRT chaplains strength and wisdom as they minister in Jesus’ Name to these men and women? Please also lift up in prayer the `}<em parentName="p">{`Sharing Hope in Crisis`}</em>{` trainings taking place across the globe—and online.`}</p>{`
    `}</div>{`
    `}<div parentName="div">{`
        `}<h5 parentName="div">{`GIVE`}</h5>{`
        `}<p parentName="div">{`Your gift to the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` helps comfort the hurting with the hope of Christ by training and deploying chaplains into crisis situations throughout the U.S. and around the world.`}</p>{`
    `}</div>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import { GetInvolved as GetInvolvedForm } from '@bgea-js/form-templates';
import "../../../../src/content/estonia-host-page/estonia-styles.scss";
import estoniaPrivacyPolicyCopy from "../../../../src/content/estonia-host-page/estoniaPrivacyPolicyCopy";
import estoniaConfirmation from "../../../../src/content/estonia-host-page/estoniaConfirmation";
import estoniaIAA from "../../../../src/content/estonia-host-page/estoniaIAACopy";
import * as React from 'react';
export default {
  GetInvolvedForm,
  estoniaPrivacyPolicyCopy,
  estoniaConfirmation,
  estoniaIAA,
  React
};
import React from 'react';

const estoniaPrivacyPolicyCopyEE = () => (
  <div>
    <p>
      Oma isikuandmete meile edastamisega annate meile nõusoleku nende
      isikuandmete kasutamiseks, et hoida teid kursis seoses oma taotlusega ja
      teavitada teid BGEA muudest tegevustest; nende andmete abil saame hallata
      teie osalust BGEAGA. Neid andmeid säilitab BGEA USA-s ja/või riigis (ja
      neid võib edastada töötlemiseks teie riigi partneritele, näiteks BGEA-UK,)
      ainult eespool nimetatud eesmärkidel. Eelistuste haldamiseks ja teie
      isikuandmete töötlemise kohta lisateabe saamiseks tutvuge
      <a
        href="https://billygraham.org/privacy-policy/"
        target="_blank"
        rel="noreferrer"
      >
        "BGEA privaatsuspoliitikaga"
      </a>
      .
    </p>
  </div>
);

export default estoniaPrivacyPolicyCopyEE;

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>
      <p parentName="div">{`Lucia—a single mom—lives in a neighborhood right next to the Palazzo. When she heard there was a free event
        there, she decided to take Bria out for the evening. God worked in her heart as she listened to the Good News,
        and she walked to the front to talk to one of our prayer counselors. Lucia shared that she was a cancer
        survivor, and she often struggled with loneliness—but`}<strong parentName="p">{` now she was ready to surrender to the One who
            promises,`}<em parentName="strong">{` “I will never leave you nor forsake you”`}</em>{` (Hebrews 13:5, NKJV)`}</strong>{`. The Lord also
        worked in Bria’s heart, and she said a prayer of repentance and faith.`}</p>{`
         `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAGdEYsysX//xAAcEAADAAEFAAAAAAAAAAAAAAABAgMABBITITH/2gAIAQEAAQUCWnbuBm/FJ5/dVGE2T//EABcRAAMBAAAAAAAAAAAAAAAAAAABElH/2gAIAQMBAT8BopYf/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EAB4QAAIBBAMBAAAAAAAAAAAAAAABEQISISJBUXGS/9oACAEBAAY/AtVsVTSrpMW/J6zKJa57P//EABsQAQEAAwADAAAAAAAAAAAAAAERACExYXGB/9oACAEBAAE/ISBBV9LcaKRXhxbL53p3OTrprCPBK6+ZZC1j/9oADAMBAAIAAwAAABBIz//EABkRAAIDAQAAAAAAAAAAAAAAAAAhARExof/aAAgBAwEBPxB0U71J/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBMVH/2gAIAQIBAT8QqxTp/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUXH/2gAIAQEAAT8QPyAbjMG9VbCbAGKjy11XkGQSmtu3MxWJGgr2q+za2BRY1CVehiJ//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "december prayer letter rome emotion",
          "title": "december prayer letter rome emotion",
          "src": "/static/37d67717571926c00126cdc7974848e3/6a068/december-prayer-letter-rome-emotion.jpg",
          "srcSet": ["/static/37d67717571926c00126cdc7974848e3/f93b5/december-prayer-letter-rome-emotion.jpg 300w", "/static/37d67717571926c00126cdc7974848e3/b4294/december-prayer-letter-rome-emotion.jpg 600w", "/static/37d67717571926c00126cdc7974848e3/6a068/december-prayer-letter-rome-emotion.jpg 960w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
         `}<figcaption parentName="div" {...{
        "className": "category"
      }}>{`WHEN FRANKLIN INVITED THE CROWD to surrender their lives to Jesus Christ, hundreds of people walked forward on both nights of the Festival.
     `}</figcaption>{`
    `}<p parentName="div">{`Benito* and Manuel* also decided to attend at the last minute. The young men were in Rome 
    for the weekend as
        tourists, and when they heard about a free concert, they decided to check it out. We praise God that the men
        stayed to hear the Gospel—and it changed their hearts and lives.`}<strong parentName="p">{` Both walked forward to talk to Fabio, one of
        our prayer counselors, and surrendered their lives to the Lord.`}</strong>{` Fabio enthusiastically answered their questions
        and stood alongside them as they prayed. “God is good,” he shared later. “I’m sure that I did almost nothing,
        but it was the Holy Spirit here. The Holy Spirit is the person that moved everything tonight.”`}</p>{`
    `}<p parentName="div"><strong parentName="p">{`Will you pray for every person who made a decision for Jesus Christ in Italy?`}</strong>{` Ask Him to make them bold witnesses
        for Him.`}</p>{`
 `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "god-loves-you-tour"
      }}>{`
        `}<span parentName="h3" {...{
          "className": "category"
        }}>{`LAW ENFORCEMENT MINISTRY`}</span>{`
    `}</h3>{`
    `}<h4 parentName="div">{`Renewing marriages, changing families`}</h4>{`
    `}<p parentName="div">{`We recently had the opportunity to minister in Jesus’ Name to `}<strong parentName="p">{`law enforcement officers and their spouses
            at the Billy Graham Training Center at The Cove`}</strong>{`. It is always an honor to host these men and women
        who regularly do so much for our communities, and I’m grateful for each person who came to the Law Enforcement
        Appreciation Retreat. During the retreat, the`}<strong parentName="p">{` 300 attendees enjoyed teaching from God’s Word,
            Christ-centered worship, and warm fellowship.`}</strong></p>{`
      `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
     `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "150%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFAwT/xAAXAQEAAwAAAAAAAAAAAAAAAAACAQME/9oADAMBAAIQAxAAAAHqTmSlUDAE4rYTQJhTCv/EABsQAAMBAAMBAAAAAAAAAAAAAAABAhEDEhMx/9oACAEBAAEFAtQ3lHqsq9pfPCpGnN4OFQ+GXXVH/8QAFxEBAAMAAAAAAAAAAAAAAAAAEQABEP/aAAgBAwEBPwFKjv8A/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQEhP/2gAIAQIBAT8Bo2zOf//EABsQAAICAwEAAAAAAAAAAAAAAAABITEQERJB/9oACAEBAAY/AkhY9FjdoSJ2jmYFZbP/xAAbEAADAQEBAQEAAAAAAAAAAAAAAREhMUFhcf/aAAgBAQABPyGyQZpvcP1wfPD8g6fU9Q7Wulr8hKDvVS16nsol0i63paNL6WbiJh//2gAMAwEAAgADAAAAENP5z//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QawLImYf/xAAYEQEBAQEBAAAAAAAAAAAAAAABABEhYf/aAAgBAgEBPxA1CfUOF2//xAAgEAEBAAEDBQEBAAAAAAAAAAABEQAhMXFBUWGhwdHx/9oACAEBAAE/EDQPpv5/MZYrt2Hqe/uDvqOpb7wbYSdTlwmREm0v9yWahR17afMLQKqRXnxhqkKCTfCmmqgZ2fEIOU3xMWlkjjGOpaGOf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "december prayer letter marriage",
            "title": "december prayer letter marriage",
            "src": "/static/50d7d0a071e9cd661a0ee7b2311d82d4/c08c5/december-prayer-letter-marriage.jpg",
            "srcSet": ["/static/50d7d0a071e9cd661a0ee7b2311d82d4/f93b5/december-prayer-letter-marriage.jpg 300w", "/static/50d7d0a071e9cd661a0ee7b2311d82d4/b4294/december-prayer-letter-marriage.jpg 600w", "/static/50d7d0a071e9cd661a0ee7b2311d82d4/c08c5/december-prayer-letter-marriage.jpg 640w"],
            "sizes": "(max-width: 640px) 100vw, 640px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
     `}</div>{`
    `}<p parentName="div">{`Sergeant Joshua Rollins, a police department chaplain, and his wife were among those who shared how much the time
        meant to them. Sergeant Rollins has served for 10 years on the force, and now he ministers to his fellow
        officers as a chaplain. He explained that the retreat was especially welcome after the department suffered a
        major tragedy when two officers recently took their own lives. “I want to see how things are done here and apply
        that to my home division,” Sergeant Rollins shared.`}</p>{`
    `}<p parentName="div">{`Will you pray for each of the officers and spouses who participated in the retreat? Pray that as they serve, they
        will be reminded of the Biblical promise that`}<em parentName="p">{` “the salvation of the righteous is from the Lord; he is their
            stronghold in the time of trouble”`}</em>{` (Psalm 37:39, ESV).`}</p>{`
        `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "god-loves-you-tour"
      }}>{`
        `}<span parentName="h3" {...{
          "className": "category"
        }}>{`CHRISTMAS AT THE LIBRARY`}</span>{`
    `}</h3>{`
    `}<h4 parentName="div">{`CELEBRATING THE GOOD NEWS OF GREAT JOY `}</h4>{`
    `}<p parentName="div">{`
        `}<strong parentName="p">{`In just a few days,`}<em parentName="strong">{` Christmas at the Library`}</em>{` will kick off,`}</strong>{` welcoming guests from all
        across the country to this annual celebration of Jesus Christ’s birth. Please pray for each man, woman, and
        child who visits during December. Each of them will have the opportunity to hear about the Savior of the world:
        `}<em parentName="p">{`“When the fullness of time had come, God sent forth his Son, born of woman, born under the law, to redeem those
        who were under the law, so that we might receive adoption” `}</em>{` (Galatians 4:4–5, ESV).
    `}</p>{`
    `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
     `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDAv/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAFNxFwTEf/EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMREhP/2gAIAQEAAQUCrZ1HpksdetCEOCC2T//EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8BmmP/xAAXEQEAAwAAAAAAAAAAAAAAAAAAARES/9oACAECAQE/Abal/8QAGhABAAIDAQAAAAAAAAAAAAAAAAEhIjEyQf/aAAgBAQAGPwKoT6xTTlp//8QAGxAAAwADAQEAAAAAAAAAAAAAAAERIVFxQaH/2gAIAQEAAT8hqbNlQdrfhqinBSXOTMPsQ0Sn/9oADAMBAAIAAwAAABAs3//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAwEBPxBVENN//8QAGBEBAQADAAAAAAAAAAAAAAAAAQARIUH/2gAIAQIBAT8QGbyxyv/EABwQAQACAgMBAAAAAAAAAAAAAAEAESExQWGBcf/aAAgBAQABPxAgDBBHHnkc5rtRt1HFupseXMcSfpY1uLaKMYZL/Qatbn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "december prayer letter library",
            "title": "december prayer letter library",
            "src": "/static/6c9bfa5974d740b569089943e202a533/6a068/december-prayer-letter-library.jpg",
            "srcSet": ["/static/6c9bfa5974d740b569089943e202a533/f93b5/december-prayer-letter-library.jpg 300w", "/static/6c9bfa5974d740b569089943e202a533/b4294/december-prayer-letter-library.jpg 600w", "/static/6c9bfa5974d740b569089943e202a533/6a068/december-prayer-letter-library.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
     `}<figcaption parentName="div" {...{
          "className": "category"
        }}>{`Thousands will come during December to experience `}<em parentName="figcaption">{` Christmas at the Library`}</em>{` in Charlotte, North Carolina.`}</figcaption>{`
     `}</div>{`
    `}<p parentName="div"><strong parentName="p">{`If you have never come for this special event, consider
            planning a trip this year`}</strong>{`. It’s the perfect opportunity to share the Gospel with your friends and
        family members who are far from the Lord.`}</p>{`
    `}<p parentName="div">{`As you celebrate what happened in Bethlehem 2,000 years ago,`}<strong parentName="p">{` will you also join me in ongoing prayer for
            Israel?`}</strong>{` There are so many hurting, grieving people who need to hear about the Lord’s love for
        them—on
        both sides of the conflict. Please `}<em parentName="p">{`“pray for the peace of Jerusalem”`}</em>{` (Psalm 122:6, NKJV) and that God
        would
        draw men, women, and children in Israel to Himself. I hope you and your loved ones enjoy a Christmas season
        filled
        with the Lord’s peace and presence.`}</p>{`
      `}<p parentName="div">{`May God richly bless you,`}</p>{`
    `}<img parentName="div" {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
        "alt": ""
      }}></img>{`
     `}<p parentName="div">{`Franklin Graham`}</p>{`
        `}<p parentName="div">{`President & CEO`}</p>{`
        `}<p parentName="div">{`*Names changed to protect privacy`}</p>{`
         `}<hr parentName="div"></hr>{`
    `}<div parentName="div" {...{
        "className": "give-background"
      }}>{`
        `}<h4 parentName="div" {...{
          "className": "cta projects mt-6"
        }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
        `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
        GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
        GOSPEL.
        `}</h4>{`
        `}<p parentName="div">{`
        `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world. 
        `}</p>{`
        `}<p parentName="div">{`
        `}<span parentName="p" {...{
            "className": "options"
          }}>{`EVANGELISTIC CRUSADES`}</span>{` | So many people are living without the hope that comes from Jesus Christ. When you send a gift for `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across the globe the chance to hear the Gospel at events like the upcoming Franklin Graham Festival in Mexico City next year.
        `}</p>{`
         `}<p parentName="div">{`
        `}<span parentName="p" {...{
            "className": "options"
          }}>{`NATIONAL LAW ENFORCEMENT MINISTRY`}</span>{` | Your gift of any amount to our `}<strong parentName="p">{` National Law Enforcement Ministry `}</strong>{`can help sponsor officers and their spouses to attend one of our marriage retreats or appreciation events—and offer spiritual resources to many in need of hope.
        `}</p>{`
        `}<p parentName="div">{`
        `}<span parentName="p" {...{
            "className": "options"
          }}>{`BILLY GRAHAM LIBRARY MINISTRY`}</span>{` | We hear from Library guests daily about how God has touched their lives—or changed their hearts—during their visit. Your gift to the `}<strong parentName="p">{`Billy Graham Library`}</strong>{` helps keep admission free, sharing the Good News of Christ with thousands of people who otherwise might not visit.
        `}</p>{`
    `}</div>{`
        
`}</div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
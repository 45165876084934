import React from 'react';

const estoniaConfirmationEE = () => (
  <div>
    <h1 className="title">Täname teid!</h1>
    <div className="block">
      <p>Teie andmete esitamine on lõpetatud.</p>
    </div>
  </div>
);

export default estoniaConfirmationEE;

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Modal = makeShortcode("Modal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <section id="speakers">
  <div className="main-container">
  <div className="margins">
    <div className="speakers-header">
      <div><h1>Congress <strong>Speakers</strong></h1></div>
      <div><p>Click on a speaker for more information on their background and session.</p></div>
    </div>
    <div className="speakers-wrapper">
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/speaker-franklin-graham.jpg' speakerName='Franklin Graham' speakerCountry='USA' speakerBio='President and CEO of the Billy Graham Evangelistic Association and Samaritan’s Purse' sessionType='Plenary Session' sessionName='“The Command of God is to Preach the Gospel”' sessionType2='Plenary Session' sessionName2='“The Time is Now”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/charles-price.jpg' speakerName='Charles Price' speakerCountry='Canada' speakerBio='Minister at Large with The Peoples Church, Toronto (2001-2016)' sessionType='Plenary Session' sessionName='“The Cross & Blood of Christ Are Central to Preaching the Gospel”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/parzany-ulrich.jpg' speakerName='Ulrich Parzany' speakerCountry='Germany' speakerBio='Evangelical pastor, theologian, and evangelist; secretary-general of the German section of the Young Men’s Christian Association' sessionType='Plenary Session' sessionName='“The Bible is Foundational for Preaching the Gospel”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/danny-pasquale.jpg' speakerName='Daniele Pasquale' speakerCountry='Italy' speakerBio='President of Instituto Biblico Evangelico Italiano' sessionType='Plenary Session' sessionName='“Discipleship is Crucial to the Preaching of the Gospel”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/joakim.jpg' speakerName='Joakim Lundqvist' speakerCountry='Sweden' speakerBio='Pastor and international ambassador of Word of Life Church in Uppsala' sessionType='Plenary Session' sessionName='“Holy Living is Mandatory for Preaching the Gospel”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/bishop-james-lagos-alexander.jpg' speakerName='James Lagos Alexander' speakerCountry='South Sudan' speakerBio='Presiding bishop of the African Inland Church' sessionType='Plenary Session' sessionName='“We Must Recognise Preaching the Gospel Has a High Cost”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/skip-heitzig.jpg' speakerName='Skip Heitzig' speakerCountry='USA' speakerBio='Senior pastor of Calvary Chapel in Albuquerque, New Mexico; board member of Samaritan’s Purse and Harvest Christian Fellowship' sessionType='Plenary Session' sessionName='“I Am Not Ashamed of the Gospel”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/gunnar-magi.jpg' speakerName='Gunnar Mägi' speakerCountry='Netherlands' speakerBio='President of Tyndale Theological Seminary' sessionType='Plenary Session' sessionName='“We Must Grasp the Utter Lostness of Mankind”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/helder-favarin.jpg' speakerName='Hélder Favarin' speakerCountry='Spain' speakerBio='Global youth and young adult initiatives director for Community Bible Study International; evangelist, missionary, author, and cofounding pastor of C29 Granada Church' sessionType='Plenary Session' sessionName='“We Must Prepare Well For Preaching the Gospel”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/will-graham.jpg' speakerName='Will Graham' speakerCountry='USA' speakerBio='Evangelist and executive vice president at the Billy Graham Evangelistic Association' sessionType='Plenary Session' sessionName='“We Must Extend an Invitation in Preaching the Gospel”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/mariusz-muszczynski.jpg' speakerName='Mariusz Muszczyński' speakerCountry='Poland' speakerBio='Pastor and regional overseer, Pentecostal Church Opole; national director of church planting and development for the Pentecostal Church of Poland' sessionType='Plenary Session' sessionName='“We Must Unite With Others in Preaching the Gospel”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/greg-laurie.jpg' speakerName='Greg Laurie' speakerCountry='USA' speakerBio='Evangelist, pastor, author, and founder of Harvest Crusades; senior pastor of Harvest Christian Fellowship; board member of the Billy Graham Evangelistic Association' sessionType='Plenary Session' sessionName='“The Gift and Calling of the Evangelist”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/albert-mohler.jpg' speakerName='Albert Mohler' speakerCountry='USA' speakerBio='President of Southern Baptist Theological Seminary; theologian, author, and speaker' sessionType='Plenary Session' sessionName='“Jesus, the Unique Son of God”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/marc-deroeux.jpg' speakerName='Marc Deroeux' speakerCountry='France' speakerBio='Executive board member of Connect Missions and Director of The Cedars School in Massy, France' sessionType='Plenary Session' sessionName='“Prayer is Essential in Preaching the Gospel”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/kanishka-raffel.jpg' speakerName='Kanishka Raffel' speakerCountry='Australia' speakerBio='Archbishop of Sydney, 2021-present; Former member of Council of The Gospel Coalition Australia and Board of GAFCON Australia' sessionType='Bible Study' sessionName='“The Authority in Evangelistic Proclamation”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/michael-reeves.jpg' speakerName='Michael Reeves' speakerCountry='UK' speakerBio='Author, pastor, lecturer, and President of Union School of Theology in Bridgend, Wales' sessionType='Bible Study' sessionName='“The Scope of Evangelistic Proclamation”' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/chris-sinkinson.jpg' speakerName='Chris Sinkinson' speakerCountry='UK' speakerBio='Author and Associate Minister at Lansdowne Church, Bournemouth, England' sessionType='Bible Study' sessionName='“The Theology of Evangelistic Proclamation”' mdxType="Modal" />
    </div>
    <hr />
    <h1 className="contributors-header"><strong>Contributors</strong></h1>
    <div className="speakers-wrapper" id="contributors">
    <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/contributor-cissie-graham-lynch.jpg' speakerName='Cissie Graham Lynch' speakerTitle='Interview Host' speakerCountry='USA' sessionName='Senior advisor and ministry spokesperson for the Billy Graham Evangelistic Association; host of Fearless podcast' mdxType="Modal" />
    <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/contributor-amy-orr-ewing.jpg' speakerName='Dr. Amy Orr-Ewing' speakerCountry='UK' sessionName='International author, speaker, and theologian; recipient of the Alphege Medal for Evangelism and Witness' mdxType="Modal" />
      <Modal img='https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/contributor-paivi-rasanen.jpg' speakerName='Päivi Räsänen' speakerCountry='Finland' sessionName='Finnish Member of Parliament from 1995-present; physician; author of seven books' mdxType="Modal" />
    </div>
  </div>
  </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
    `}<p parentName="div">{`Dear Friend,`}</p>{`
    `}<p parentName="div">{`What an incredible privilege it was to preach the Gospel of Jesus Christ in Rome earlier this month.`}<strong parentName="p">{`
            During the two-day Noi Festival, more than 18,000 people heard the Good News—and the Holy Spirit drew more
            than a thousand of them to respond to `}<em parentName="strong">{`“the message of this salvation”`}</em>{` (Acts 13:26, ESV)`}</strong>{`.
        Many more heard the Gospel proclaimed as they watched online.`}</p>{`
        `}<div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFb49oeQEv/xAAZEAEBAQADAAAAAAAAAAAAAAABAgMREjL/2gAIAQEAAQUCK5o1Vrdhg7bFLenv/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AUn/xAAZEAADAQEBAAAAAAAAAAAAAAAAARFxEpH/2gAIAQEABj8C4ek8IN0wZ//EABoQAQEBAQADAAAAAAAAAAAAAAERACFhkaH/2gAIAQEAAT8herQfbJFLfrczvnLySOPVIOE5k9Gzm//aAAwDAQACAAMAAAAQDB//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8Qhr//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QEyf/xAAbEAEBAAMBAQEAAAAAAAAAAAABEQAhUTFBYf/aAAgBAQABPxCOcgBBHfyZrKyX3xPujPSELslu+5dSwwttxq2EpQjBncYVraeGs//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "december prayer letter social",
            "title": "december prayer letter social",
            "src": "/static/2d4d7c44809d356f849313ca60adaba7/6a068/december-prayer-letter-social.jpg",
            "srcSet": ["/static/2d4d7c44809d356f849313ca60adaba7/f93b5/december-prayer-letter-social.jpg 300w", "/static/2d4d7c44809d356f849313ca60adaba7/b4294/december-prayer-letter-social.jpg 600w", "/static/2d4d7c44809d356f849313ca60adaba7/6a068/december-prayer-letter-social.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
        `}<figcaption parentName="div" {...{
          "className": "category"
        }}>{`EACH NEW BELIEVER received their own copy of God’s Word, along with other material to help them grow in their faith.`}</figcaption>{`
        `}</div>{`
    `}<p parentName="div">{`I’ve been to Rome many times over the years, but this is the first time we were able to hold a Crusade there. The
        hunger among the Italian people to hear God’s Word was incredible.`}</p>{`
    `}<p parentName="div">{`For months, Christians from more than 800 evangelical churches throughout Italy prayed and prepared for this
        Gospel outreach.`}</p>{`
    `}<p parentName="div">{`In the week leading up to the event, we also hosted Preach the Word, a daylong pastor’s conference on evangelism
        that was led by my good friend Skip Heitzig. Over 250 Christian leaders from more than 10 denominations came
        together from Pordenone in the north, near the Austrian border, to Puglia in the south, close to the heel of
        Italy’s boot.`}</p>{`
    `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
        `}<figure parentName="div" {...{
          "className": "img"
        }}>{`
     `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFdUm0vEE1//8QAHRAAAQMFAQAAAAAAAAAAAAAAAgEDIgAEERITM//aAAgBAQABBQIHBFQcTkmCraBFC28v/8QAFxEAAwEAAAAAAAAAAAAAAAAAAhARMf/aAAgBAwEBPwEZi//EABgRAQADAQAAAAAAAAAAAAAAAAECEBEh/9oACAECAQE/AZD1yv/EABoQAAMAAwEAAAAAAAAAAAAAAAABEUFRYYH/2gAIAQEABj8Cb4N6KiQkSyen/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARMSFh/9oACAEBAAE/IUYrdCOqddbsQaTGikgNBKxM31P/2gAMAwEAAgADAAAAEDTv/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EFgDP//EABcRAAMBAAAAAAAAAAAAAAAAABARQWH/2gAIAQIBAT8Qdg0f/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBUaHB8P/aAAgBAQABPxC2EZAu02w6hRVtb5hVAaa4eoVOeW1lBN9y9UDDWXEzYWvha9T/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "december prayer letter conference",
              "title": "december prayer letter conference",
              "src": "/static/3dfb44c82f2b94ddc7a9173de7ad8d92/6a068/december-prayer-letter-conference.jpg",
              "srcSet": ["/static/3dfb44c82f2b94ddc7a9173de7ad8d92/f93b5/december-prayer-letter-conference.jpg 300w", "/static/3dfb44c82f2b94ddc7a9173de7ad8d92/b4294/december-prayer-letter-conference.jpg 600w", "/static/3dfb44c82f2b94ddc7a9173de7ad8d92/6a068/december-prayer-letter-conference.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
          `}</figure>{`
     `}</div>{`
    `}<p parentName="div">{`The conference was a historic gathering. Leaders told us that this kind of unity hasn’t been seen before across
        their nation’s churches. They were thrilled to come together for worship, fellowship, and Biblical sessions on
        sermon preparation, expository preaching, and evangelism. We thank God for the great encouragement it was to
        them in their ministries.`}</p>{`
    `}<p parentName="div">{`Between both nights of the Festival, over 150 buses brought people from as far as six hours away to the Palazzo
        dello Sport—Roma. When I gave the invitation to receive Jesus Christ in repentance and faith, Lucia* and her
        11-year-old daughter Bria* were among those who walked forward.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      